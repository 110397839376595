<!--
	描述：重定向 =》 用于路由刷新
	时间：2020-04-23
	Created by ws
-->
<template>
	<div class="">

	</div>
</template>

<script>
export default {
	data() {
		return {}
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			// console.log(vm.$router)
			vm.$router.replace({
				path: '/' + to.params.path,
				query: to.query
			})
		})
	},
	methods: {}
}
</script>

<style scoped lang="less">
</style>

